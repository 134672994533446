export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavTitle',
        _children: ['Admin']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Categories',
        to: '/categories/list',
        icon: 'cil-drop'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Places',
        to: '/places/list',
        icon: 'cil-pencil'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Users',
        to: '/user/list',
        icon: 'cil-chart-pie'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Broadcast',
        to: '/broadcast/list',
        icon: 'cil-chart-pie'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Log History',
        to: '/log-history',
        icon: 'cil-chart-pie'
      }
    ]
  }
]