<template>
    <div class="c-app">
        <TheSidebar/>
        <CWrapper>
            <TheHeader/>
            <div class="c-body">
                <main class="c-main">
                    <CContainer fluid>
                        <transition name="fade">
                            <router-view></router-view>
                        </transition>
                    </CContainer>
                </main>
            </div>
        </CWrapper>
    </div>
</template>

<script>
  import TheSidebar from '@/components/layout/TheSidebar'
  import TheHeader from '@/components/layout/TheHeader'

  export default {
    name: 'DefaultLayout',
    components: {
      TheSidebar,
      TheHeader,
    }
  }
</script>

<style scoped>
</style>
